const report = {
  salesReport: () => `store/report/sales`,
  salesReturn: () => `store/report/sales-return`,
  inventoryReport: () => `store/report/inventory`,
  gstOutputReport: () => `store/report/gst-output`,
  glAccountReport: () => `admin/report/gl-account`,
  generalAccountList: () => 'admin/report/account/search',
  dailyActivityReport:()=> 'store/report/daily-activity',
  inventoryAdditionalInvoiceWiseReport: () => `store/report/inventory-addition`,
  inventoryAdditionalBarcodeWiseReport: () => `store/report/inventory-addition-barcode-wise`,

  // for e-com
  eComOrder:()=>'store/report/ecom-orders',
}
export default report