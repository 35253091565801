export default {
  dev_URL: 'https://dev-store.specxweb.com',
  uat_URL: 'https://uat-store.specxweb.com',

  LIMIT: 10,

  PRODUCT_lIST_PATH: '/product/list',

  SCOPE: {
    ADMIN: '1',
    MANAGER: '2',
    STAFF: '3',
  },
  VERIFICATION_STATUS: {
    PENDING: 1,
    VERIFIED: 2,
    REWORK: 3,
  },
  PROCUREMENT_ITEM_STATUS: {
    PENDING: 1,
    TO_BE_DELIVERED: 2,
    DELIVERED: 3,
    CANCEL: 4,
  },
  BARCODE_STATUS: {
    PENDING: 1,
    APPROVED: 2,
  },
  STORE_FURNITURE_TYPE: [
    { label: 'Luxury AC Showroom', value: 1 },
    { label: 'Premium AC Showroom', value: 2 },
    { label: 'Non AC Good Furnised Store', value: 3 },
    { label: 'Basic Furnised Store', value: 4 },
    { label: 'Low Furnised Store', value: 5 },
  ],
  STATUS: {
    ACTIVE: 'Active',
    DEACTIVE: 'Deactive',
  },
  GENDER: [
    { label: 'Male', value: 1 },
    { label: 'Female', value: 2 },
    { label: 'Kids', value: 3 },
  ],
  PRODUCT_WEIGHT: [
    { label: 'Light', value: 1 },
    { label: 'Average', value: 2 },
    { label: 'Above Average', value: 3 },
  ],
  // PRODUCT_AGE_GROUP: [
  //   { label: 'Kids', value: 1 },
  //   { label: 'Teenager', value: 2 },
  //   { label: 'Adult', value: 3 },
  //   { label: 'Old', value: 4 },
  // ],
  // PRODUCT_FEATURES: [
  //   { label: 'Matt', value: 1 },
  //   { label: 'Gradient', value: 2 },
  //   { label: 'Transparent', value: 3 },
  //   { label: 'Blue Cut', value: 4 },
  //   { label: 'Mirror', value: 5 },
  //   { label: 'Photocromatic', value: 6 },
  //   { label: 'Demi Havana', value: 7 },
  //   { label: 'Fis Nosepad', value: 8 },
  // ],
  PRODUCT_FEATURES: [
    {
        label: "BLUE CUT",
        value: 4
    },
    {
        label: "DEMI HAVANA",
        value: 7
    },
    {
        label: "FIX NOSEPAD",
        value: 8
    },
    {
        label: "GRADIENT",
        value: 2
    },
    {
        label: "MATT",
        value: 1
    },
    {
        label: "MIRROR",
        value: 5
    },
    {
        label: "PHOTOCROMATIC",
        value: 6
    },
    {
        label: "TRANSPARENT",
        value: 3
    },
    {
        label: "PROGRESSIVE",
        value: 9
    }
],
  PRODUCT_RIM_TYPE: [
    { label: 'Full Rim', value: 1 },
    { label: 'Half Rim', value: 2 },
    { label: 'Rim Less', value: 3 },
  ],
  PRODUCT_WARRANTY: [
    { label: '6 Months', value: 1 },
    { label: '12 Months', value: 2 },
    { label: '24 Months', value: 3 },
  ],

  POLARIZED: [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 2 },
    { label: 'NA', value: 3 },
  ],
  GST_DETAILS: {
    REGULAR: 1,
    COMPOSITION: 2,
    NA: 3,
  },
  DOC_TYPE: {
    gst_number: 'GST',
    gst_document: 'GST',
    pan_number: 'PAN',
    pan_document: 'PAN',
    gumasta_number: 'GUMASTA',
    gumasta_document: 'GUMASTA',
    nagar_nigam_number: 'NAGAR_NIGAM',
    nagar_nigam_document: 'NAGAR_NIGAM',
    incorporation_number: 'INCORPORATION',
    incorporation_document: 'INCORPORATION',
    agency_number: 'AGENCY',
    agency_document: 'AGENCY',
  },

  // INVOICE_CATEGORY: [
  //   // { label: 'SPECXWEB INVOICE', value: 1 },
  //   // { label: 'WARRANTY', value: 2 },
  //   // { label: 'FREE GOODS', value: 3 },
  //   // { label: 'SAMPLE', value: 4 },
  //   // { label: 'INVOICE', value: 1 },
  //   // { label: 'WARRANTY', value: 2 },
  //   // { label: 'FREE GOODS', value: 3 },
  //   // { label: 'SAMPLE', value: 4 },
  //   // { label: 'STORE OWNERSHIP', value: 5 },
  //   { label: 'EXISTING INVENTORY', value: 5 },
  //   { label: 'NEW PURCHASE', value: 6 },
  // ],

  ProductCancel: [
    { label: 'Product Not found', value: 'Product Not found' },
    { label: 'Product Damaged', value: 'Product Damaged' },
    { label: 'Product Not salable', value: 'Product Not salable' },
    { label: 'Others', value: 'Others' },
  ],
  INVENTORY_ITEM_CONDITION: [
    { label: 'Good Saleable', value: 1 },
    { label: 'Minor Defect', value: 2 },
    { label: 'Major Defect', value: 3 },
    { label: 'Not Saleable', value: 4 },
  ],

  TRANSACTION_TYPE: [
    { label: 'Credit', value: 1 },
    { label: 'Debit', value: 2 },
  ],

  ACCOUNTING_EVENTS: [
    { label: 'INVOICE ADDITION', value: 1 },
    { label: 'INVENTORY MOVEMENT', value: 2 },
    { label: 'INVOICE PREPARED ', value: 3 },
    { label: 'ADVANCE RECEIPT PREPARED', value: 4 },
    { label: 'ADVANCE RECEIPT CANCELLED', value: 5 },
    { label: 'INVENTORY RETURNED', value: 6 },
    { label: 'MANUAL ENTRY', value: 7 },
    { label: 'PAYMENT VOUCHER', value: 8 },
    { label: 'PRICE UPDATE', value: 9 },
    { label: 'WARRANTY CLAIMED', value: 10 },
    { label: 'BRAND COST REVISED', value: 11 },
    { label: 'LOST_BARCODE', value: 12 },
    { label: 'INTERNAL TRANSFER', value: 13 },
  ],

  DailyReportFormat: [
    { value: 1, label: 'PDF' },
    { value: 2, label: 'EXCEL' },
  ],

  DailyReportFormatForExcel: [{ value: 2, label: 'EXCEL' }],

  Daily_Report_Type: [
    { label: 'Collection Summary', value: 1 },
    { label: 'Payments Summary', value: 2 },
    { label: 'Pending Orders Summary', value: 3 },
    { label: 'Completed Order summary', value: 4 },
    { label: ' Orders Cancelled Summary', value: 5 },
    { label: 'Inventory Audit Summary', value: 6 },
    { label: 'Inventory Movement Summary', value: 7 },
  ],
  BooksOfAccount: [
    { value: true, label: 'Specxweb' },
    { value: false, label: 'Book Of Stores' },
  ],

  Product_List_Price_Sort: {
    High_To_Low: 1,
    Low_To_High: 2,
  },
  BRAND_CATEGORY: [
    { label: 'Recognized', value: 1 },
    { label: 'Unrecognized', value: 2 },
  ],
  LENS_TYPE: [
    { label: 'SPERICAL', value: 1 },
    { label: 'TORIC', value: 2 },
    { label: 'MULTI FOCAL', value: 3 },
  ],
  PERIOD_USE: [
    { label: 'DAILY', value: 1 },
    { label: 'BI-MONTHLY', value: 2 },
    { label: 'MONTHLY', value: 3 },
    { label: 'YEARLY', value: 4 },
  ],
  INVOICE_CATEGORY: [
    { label: 'SPECXWEB INVOICE', value: 1 },
    { label: 'WARRANTY', value: 2 },
    { label: 'FREE GOODS', value: 3 },
    { label: 'SAMPLE', value: 4 },
    { label: 'EXISTING INVENTORY', value: 5 },
    { label: 'NEW PURCHASE', value: 6 },
  ],
  STORE_INVOICE_CATEGORY: [
    { label: 'EXISTING INVENTORY', value: 5 },
    { label: 'NEW PURCHASE', value: 6 },
  ],
  PACK_OF: [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '10', value: 10 },
    { label: '12', value: 12 },
    { label: '30', value: 30 },
    { label: '90', value: 90 },
  ],
  PRODUCT_FILTER_TYPE:{
    EYE_GLASSES:'frame',
    SUN_GLASSES:'sunglass',
    CONTACT_lENS:'contact_lens',
    READING_GLASSES:'reading',
    COMPUTER_GLASSES:'computer_glasses',
    DEFAULT:'default',
  },
  Product_Transit_Days_Undefined : "undefined - undefined Days"
}
