import React from 'react'
import { Navigate } from 'react-router'
import privatePages from '../pages/App'
import publicPages from '../pages/Auth'
const { Dashboard, ProductList, ProductDetail, StaffAdd, Staff, OrderCart, OrderHistory, OrderHistoryItems,
    OrderReceived, OrderCancelled, TransferRequested, TransferRequestMoveOut, TransferRequestCancelled, InventoryReport,
    Inventory, Transaction, GeneralAccount, ChangePassword, InventoryAudit, Brand, DailyActivityReport, GSTOutputReport, SalesReport, InventoryAdditionReport,
    InventoryDashboard, OrderDashboard, AccountsDashboard, SalesReturn, AddInventory, AddProduct, Setting, ApproveInventory, PendingInventory,
    AddBrand ,BulkUpload,Product,Barcode,ApproveBarcode,PriceUpdatedBarcode,SubAdmin,AddSubAdmin,Greeting,PendingProduct,LostBarcodeHistory, LostBarcode, 
    InternalRequest,InternalMoveOut,EComOrdersReport} = privatePages
const { Login, Registration } = publicPages

const privateRoutes = [
    { path: "/", exact: true, component: <Navigate to="/dashboard" />, },
    { path: '/dashboard', component: <Dashboard /> },
    { path: '/inventory/detail', component: <InventoryDashboard /> },
    { path: '/order/detail', component: <OrderDashboard /> },
    { path: '/product/list', component: <ProductList /> },
    { path: '/accounts/gl', component: <AccountsDashboard /> },
    { path: '/product/details/:id', component: <ProductDetail /> },

    { path: '/product', component: <Product /> },
    { path: '/pending-product', component: <PendingProduct /> },

    { path: '/staff', component: <Staff /> },
    { path: '/staff/add', component: <StaffAdd /> },
    { path: '/staff/edit/:id', component: <StaffAdd /> },
    { path: '/order-cart', component: <OrderCart /> },

    { path: '/procurement/order/history', component: <OrderHistory /> },
    { path: '/procurement/order/history/product-detail/:id', component: <OrderHistoryItems /> },
    { path: '/procurement/order/received', component: <OrderReceived /> },
    { path: '/procurement/cancelled', component: <OrderCancelled /> },

    { path: '/product/add', component: <AddProduct /> },
    { path: '/product/edit/:id', component: <AddProduct /> },
    { path: '/bulk-upload-product', component: <BulkUpload /> },


    { path: '/transfer-request/requested', component: <TransferRequested /> },
    { path: '/transfer-request/moved-out', component: <TransferRequestMoveOut /> },
    { path: '/transfer-request/cancelled', component: <TransferRequestCancelled /> },

    { path: '/internal-request/request', component: <InternalRequest /> },
    { path: '/internal-request/moved-out', component: <InternalMoveOut /> },

    { path: '/inventory/search', component: <Inventory /> },
    { path: '/inventory/add', component: <AddInventory /> },
    { path: '/inventory/edit/:id', component: <AddInventory /> },

    { path: '/inventory/approved', component: <ApproveInventory /> },
    { path: '/inventory/pending', component: <PendingInventory /> },

    { path: '/accounts/transaction', component: <Transaction /> },
    { path: '/accounts/general-account', component: <GeneralAccount /> },

    { path: '/report/inventory', component: <InventoryReport /> },
    { path: '/report/daily-activity', component: <DailyActivityReport /> },
    { path: '/report/gst', component: <GSTOutputReport /> },
    { path: '/report/sales', component: <SalesReport /> },
    { path: '/report/sales-return', component: <SalesReturn /> },
    { path: '/report/inventory-addition', component: <InventoryAdditionReport /> },
    { path: '/report/e-com-orders', component: <EComOrdersReport /> },

    // for setting page
    { path: '/setting', component: <Setting /> },
    { path: '/settings/greeting', component: <Greeting /> },
    { path: '/sub-admin', component: <SubAdmin /> },
    { path: '/sub-admin/add', component: <AddSubAdmin /> },
    { path: '/sub-admin/edit/:id', component: <AddSubAdmin /> },

    { path: '/change-password', component: <ChangePassword /> },
    { path: '/brand', component: <Brand /> },
    { path: '/brand/add', component: <AddBrand /> },
    
    { path: '/pending-barcode', component: <Barcode /> },
    { path: '/approve-barcode', component: <ApproveBarcode /> },
    { path: '/price-updated-barcode', component: <PriceUpdatedBarcode /> },
    { path: '/inventory/lost-barcode-history', component: <LostBarcodeHistory /> },
    { path: '/inventory/lost-barcode', component: <LostBarcode /> },
]

const PublicRoutes = [
    { path: '/login', component: <Login /> },
    { path: '/registration', component: <Registration /> },
]
export { privateRoutes, PublicRoutes }